import React from 'react';
import FeatureShowcase from '../../components/home/FeatureShowcase';
import googleDocsIcon from '../../images/alternative-to/googleDocsIcon.svg';
import googleDocsLogo from '../../images/alternative-to/googleDocsLogo.png';
import collaborateIcon from '../../images/feature-icons/collaborate-icon.svg';
import integrateIcon from '../../images/feature-icons/integrate.svg';
import organizationIcon from '../../images/feature-icons/organize-icon.svg';
import retrieveIcon from '../../images/feature-icons/retrieve-icon.svg';
import integrateIllustration from '../../images/home/features/integrate.svg';
import organizeIllustration from '../../images/home/features/organize.svg';
import retrieveIllustration from '../../images/home/features/retrieve.svg';
import verifyIllustration from '../../images/home/features/verify.svg';
import ComparisonTemplate from '../../templates/ComparisonTemplate';

const Page: React.SFC = (props) => (
  <ComparisonTemplate
    competitor={{
      name: 'Google Docs',
      icon: googleDocsIcon,
      fullLogo: googleDocsLogo,
    }}
    metaTitle="Alternative to Google Docs for Knowledge Sharing - Slab"
    metaDescription="With Slab, your team can organize, discover, and search valuable company knowledge."
    header="Why go with Slab vs Google Docs?"
    subheader="Slab's organization features, ease of use, and integrations make it the #1 choice to scale beyond Google Docs for team knowledge sharing."
    features={
      <>
        <FeatureShowcase
          icon={collaborateIcon}
          title="Organized"
          headline="Folders are not enough"
          description="Slab allows flexible organization with relevant context to make it easy for your team to retrieve important company policies and processes and discover valuable knowledge."
          illustration={organizeIllustration}
          remarkLogo={googleDocsIcon}
          remark="Folders are just a list of files, missing crucial details like which files are important, which to read in what order, and who to ask for help."
        />
        <FeatureShowcase
          icon={organizationIcon}
          title="Verification"
          headline="Knowledge you can trust"
          description="Verification ensures team knowledge is regularly reviewed, updated, and verified by its designated maintainer, so teammates can trust what they find."
          illustration={verifyIllustration}
          reversed
          remarkLogo={googleDocsIcon}
          remark="In Google Docs, teammates are left guessing whether content is stale or outdated."
        />
        <FeatureShowcase
          icon={retrieveIcon}
          title="Search"
          headline="Not yet another place to search"
          description="With Unified Search, we'll search for answers from Slab and all of your integrations — including Google Docs and Drive — delivering the most relevant results all within Slab."
          illustration={retrieveIllustration}
          remarkLogo={googleDocsIcon}
          remark="Google Docs lacks functionality to search integrations' contents."
        />
        <FeatureShowcase
          icon={integrateIcon}
          title="Integrate"
          headline="Integrate with tools you choose"
          description="Slab is a best-in-class product for team knowledge sharing with first-class integrations with everything else. This includes Google Docs and anything else you use for spreadsheets, flowcharts, task managers, and much more."
          illustration={integrateIllustration}
          reversed
          remarkLogo={googleDocsIcon}
          remark="Outside of Google's stack, integrations are built and maintained by third parties."
        />
      </>
    }
    comparisons={[
      {
        title: 'Unlimited posts',
        description: 'No limit to the quanity or length.',
        slab: { supported: true },
        competitor: { supported: true },
      },
      {
        title: 'Version History',
        description: 'Full history of changes on posts.',
        slab: { supported: true },
        competitor: { supported: true },
      },
      {
        title: 'Flexible Organization',
        description: 'More context than just a list of files.',
        slab: { supported: true },
        competitor: { supported: false, remark: 'Folders' },
      },
      {
        title: 'Unified Search',
        description: 'Search across native content and integrations.',
        slab: { supported: true },
        competitor: { supported: false },
      },
      {
        title: 'Verification',
        description: 'Avoid stale and outdated knowledge.',
        slab: { supported: true },
        competitor: { supported: false },
      },
      {
        title: 'Analytics',
        description:
          'Insights into team usage, contribution, and engagement teamwide.',
        slab: { supported: true },
        competitor: { supported: false },
      },
    ]}
    {...props}
  />
);

export default Page;
